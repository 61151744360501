import { Button } from "react-bootstrap";
import { isAndroid, isIOS } from "react-device-detect";
import { APP_STORE_URL, PLAY_STORE_URL } from "shared/settings/config";

export const MobileAppBanner = () => {
    const openApp = () => {
        // Set the deep link for the app
        const appLink = "ctbidsbuyer://"; // Replace with your app's custom scheme URL
        const fallbackLink = isAndroid ? PLAY_STORE_URL : isIOS ? APP_STORE_URL : null // fallback URL
        const timeout = 500; // Time before we assume the app isn't installed
   
        const startTime = Date.now();
        const iframe = document.createElement('iframe');
        iframe.style.visibility = 'hidden';
        iframe.src = appLink;

        // Append iframe to trigger deep link
        document.body.appendChild(iframe);

        // If the app isn't installed, after the timeout, it will fallback to a web page
        setTimeout(() => {
            const now = Date.now();
            const diff = now - startTime;
            const isAppOpened = diff < timeout;
            if (document.hasFocus() && fallbackLink) {
                window.location.href = fallbackLink; // Redirect to fallback url if the app is not installed
            }
        }, timeout);
    }

    return (
        isAndroid || isIOS ? 
            <div className='d-flex justify-content-between p-2' style={{ backgroundColor: "#000000", color: "white" }}>
                <div className='d-flex flex-column'>
                    <div><img src={`${process.env.PUBLIC_URL}/fav.png`} className='home' style={{ width: "8%" }} />CTBids</div>
                    <div>Open in the CTBids App</div>
                </div>
                <Button variant="secondary mx-2" style={{ color: "white", width: "20%" }} onClick={openApp}>OPEN</Button>
            </div> : ''
    )
}